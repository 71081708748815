<template>
  <router-link
    class="recommend-item-big"
    :to="{ name: 'NewsDetail', query: { id: data.id } }"
  >
    <div class="item-content">
      <div class="content-img">
        <img v-lazy="data.img"/>
      </div>
      <span class="content-rank" :class="'content-rank--' + rank" v-if="rank <= 2"/>
      <div class="content-title" :title="data.title">
        <p class="in-two-line">{{ data.title }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'NewsItemSmall',
  props: {
    data: {
      type: Object,
      required: true
    },
    rank: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.recommend-item-big {
  .item-content {
    position: relative;
    width: 300px;
    height: 200px;
    color: #fff;
    background-color: #edf1f4;
    border-radius: 2px;
    &:hover {
      color: #FFA73D;
      .content-img {
        img {
          transform: scale(1.12);
        }
      }
    }
    .content-img {
      width: 300px;
      height: 200px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        transition: all 1s;
      }
    }
    .content-rank {
      padding: 19px 30px;
      position: absolute;
      top: 0;
      left: -10px;
      z-index: 1;
      background: url(../assets/img/news.png) no-repeat;
      background-size: 246px 1012px;
      &--1 {
        background-position: 0 -153px;
      }
      &--2 {
        background-position: 0 -191px;
      }
    }
    .content-title {
      border-radius: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background-image: linear-gradient(-180deg, transparent 13%, rgba(0, 0, 0, .65) 97%);
      p {
        font-size: 14px;
        line-height: 20px;
        text-align: justify;
        padding-left: 10px;
        padding-right: 16px;
        max-height: 40px;
        position: absolute;
        bottom: 10px;
        overflow: hidden;
      }
    }
  }
}
</style>
