<template>
  <div class="page-content news-page">
    <div class="banner-area">
      <div class="banner-slogan">
        <h2 class="title">零方资讯</h2>
        <h4 class="desc">及时获取覆盖全场景的数智化新零售方案的前沿信息。</h4>
      </div>
    </div>
    <div class="news-detail-area" v-loading="isLoading">
      <div class="detail-content-wrapper">
        <h3 class="detail-title">{{ title }}</h3>
        <ul class="detail-tag" v-if="tagList.length">
          <li class="tag-item" v-for="item in tagList" :key="'tag-' + item">
            <el-tag size="small" type="warning" v-if="item">{{ item }}</el-tag>
          </li>
        </ul>
        <div class="detail-date">发布时间: {{ createdTime }}</div>
        <article class="detail-content" v-html="detailData" v-if="detailData"/>
        <div class="detail-content detail-content--empty" v-else>暂无详情</div>
      </div>
      <div class="recommend-list">
        <div
          class="list-item"
          v-for="(item,index) in recommendList"
          :key="item.id"
        >
          <NewsRecommendItemBig
            :key="item.id"
            :data="item"
            :rank="index + 1"
            v-if="index <= 1"
          />
          <NewsRecommendItem
            :key="item.id"
            :data="item"
            :rank="index + 1"
            v-else
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import NewsRecommendItemBig from '../components/NewsRecommendItemBig'
import NewsRecommendItem from '../components/NewsRecommendItem'
import { description, keywords } from '../data/meta'

export default {
  name: 'NewsDetail',
  metaInfo: {
    title: '零方科技 | 零方资讯',
    meta: [
      {
        name: 'description',
        content: description
      },
      {
        name: 'keywords',
        content: keywords + ',零方资讯，零方洞察，产品动态'
      }
    ]
  },
  components: { NewsRecommendItemBig, NewsRecommendItem },
  data() {
    return {
      title: '',
      createdTime: '',
      tagList: [],
      detailData: '',
      recommendList: [
        {
          title: '产品动态',
          type: 0,
          img: require('../assets/img/xinwen_pic1.jpg')
        },
        {
          title: '零方洞察',
          type: 1,
          img: require('../assets/img/xinwen_pic2.jpg')
        },
        {
          title: '零方动态',
          type: 2,
          img: require('../assets/img/xinwen_pic3.jpg')
        }
      ],
      isLoading: false
    }
  },
  watch: {
    $route() {
      this.getNewsDetail()
      this.getRecommendNews()
    }
  },
  methods: {
    getNewsDetail() {
      if (!this.$route.query.id) {
        this.$message.error('非法进入，请从正确的入口进入')
        return
      }
      const self = this
      self.isLoading = true
      axios.get('/api/Open/GetNewsDetail', {
        params: {
          newsId: this.$route.query.id
        }
      })
        .then(response => {
          self.isLoading = false
          response = response.data
          if (response.Code === '0') {
            self.detailData = response.Data.Content
            self.title = response.Data.Title
            self.createdTime = response.Data.CreateTime
            self.tagList = response.Data.Keyword ? response.Data.Keyword.split(',') : []
          }
        })
        .catch(error => {
          self.isLoading = false
          console.log(error)
        })
    },
    getRecommendNews() {
      const self = this
      self.isLoading = true
      axios.get('/api/Open/GetNewsList', {
        params: {
          exceptId: self.$route.query.id,
          pageIndex: 1,
          pageSize: 5
        }
      })
        .then(response => {
          self.isLoading = false
          response = response.data
          if (response.Code === '0') {
            self.recommendList = self.dealRecommendList(response.Data.List)
          }
        })
        .catch(error => {
          self.isLoading = false
          console.log(error)
        })
    },
    dealRecommendList(data) {
      const result = []
      data.forEach(item => {
        result.push({
          id: item.Id,
          img: item.Cover,
          title: item.Title,
          desc: item.Desc,
          date: item.CreateTime
        })
      })
      return result
    }
  },
  created() {
    this.getNewsDetail()
    this.getRecommendNews()
  }
}
</script>

<style lang="less" scoped>
.news-page {
  width: 100%;
  .banner-area {
    width: 100%;
    height: 500px;
    background: url("../assets/img/xw_banner.jpg") no-repeat;
    //background-position: center;
    .banner-slogan {
      width: 1100px;
      margin: 0 auto;
      padding-top: 220px;
      color: #ffffff;
      .title {
        font-size: 46px;
        font-weight: 600;
        margin-bottom: 40px;
      }
      .desc {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
  .news-detail-area {
    width: 1200px;
    margin: 40px auto;
    display: flex;
    .detail-content-wrapper {
      width: 820px;
      padding: 0 32px 0 20px;
      margin-top: 20px;
      min-height: 80px;
      //font-size: 14px;
      //line-height: 28px;
      border-right: 1px #f1f1f1 solid;
      .detail-title {
        font-size: 28px;
        font-weight: 700;
        margin: 1em 0 0.6em 0;
      }
      .detail-tag {
        width: 100%;
        margin: 8px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        .tag-item {
          margin-right: 8px;
          margin-bottom: 6px;
        }
      }
      .detail-date {
        margin: 8px 0 36px 0;
        color: #999999;
      }
      .detail-content {
        width: 100%;
        min-height: 180px;
        &--empty {
          padding-top: 20px;
          color: #999;
          display: flex;
          justify-content: center;
        }
      }
    }
    .recommend-list {
      flex: none;
      width: 340px;
      padding: 20px 0;
      float: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      .list-item {
        margin-top: 14px;
      }
    }
  }
}
</style>

<style scoped>
.detail-content >>> p,
ul,
menu,
dir,
h3 {
  max-width: 100%;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.detail-content >>> h1 {
  max-width: 100%;
  font-weight: bold;
  font-size: 32px;
  margin: 21px 0;
}
.detail-content >>> h2 {
  max-width: 100%;
  font-weight: bold;
  font-size: 24px;
  margin: 19px 0;
}
.detail-content >>> h3 {
  max-width: 100%;
  font-weight: bold;
  font-size: 19px;
  margin: 18px 0;
}
.detail-content >>> h4 {
  max-width: 100%;
  font-weight: bold;
  font-size: 16px;
  margin: 21px 0;
}
.detail-content >>> h5 {
  max-width: 100%;
  font-weight: bold;
  font-size: 13px;
  margin: 22px 0;
}
.detail-content >>> h6 {
  max-width: 100%;
  font-weight: bold;
  font-size: 11px;
  margin: 24px 0;
}
.detail-content >>> img {
  max-width: 100%;
}
.detail-content >>> option {
  padding: 0px 2px 1px;
}
.detail-content >>> input {
  padding: 1px;
}
.detail-content >>> fieldset {
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
}
.detail-content >>> textarea {
  padding: 2px;
}
.detail-content >>> legend {
  padding-inline-start: 2px;
  padding-inline-end: 2px;
}
.detail-content >>> a {
  /*text-decoration: underline;*/
}
.detail-content >>> ul {
  padding-left: 40px;
  padding-right: 40px;
  list-style: unset;
}
.detail-content >>> ol {
  list-style: decimal;
  padding-left: 40px;
  padding-right: 40px;
}
.detail-content >>> li {
  display: list-item;
  list-style: unset;
}
.detail-content >>> p {
  max-width: 100%;
  min-height: 1.5em;
}
</style>
