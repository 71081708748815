<template>
  <div class="news-item" :class="`news-item--${mode}`">
    <div class="item-pic">
      <img v-lazy="data.img"/>
    </div>
    <div class="item-content">
      <div class="in-one-line content-title">
        <router-link :to="{ name: 'NewsDetail', query: { id: data.id } }">{{ data.title }}</router-link>
      </div>
      <div class="in-six-line content-desc">{{ data.desc }}</div>
      <div class="content-bottom">
        <div class="content-date">零方科技 {{ data.date }}</div>
        <router-link :to="{ name: 'NewsDetail', query: { id: data.id } }" class="content-link">查看详情 <i
          class="icon-next"/></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsItem',
  props: {
    data: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: 'light'
    }
  }
}
</script>

<style lang="less" scoped>
.news-item {
  width: 100%;
  height: 235px;
  margin: 30px 0;
  display: flex;
  .item-pic {
    width: 360px;
    height: 235px;
    margin-right: 40px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 12px 12px 12px 12px;
      box-shadow: 0 4px 4px #e1e1e1;
    }
  }
  .item-content {
    width: calc(100% - 400px);
    border-bottom: 1px #e6e6e6 solid;
    .content-title {
      width: 100%;
      padding-right: 40px;
      font-size: 24px;
      font-weight: 400;
      line-height: 33px;
      text-align: left;
      color: #333333;
      overflow: hidden;
      margin-bottom: 10px;
      transition: all .2s ease-in-out;
      a:hover {
        color: #777 !important;
      }
    }
    .content-desc {
      width: 100%;
      height: 148px;
      font-size: 14px;
      line-height: 24px;
    }
    .content-bottom {
      width: 100%;
      line-height: 40px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      .content-date {
      }
      .content-link {
        color: #F17D1A;
      }
    }
  }
  &--dark {
    .item-pic {
      img {
        box-shadow: none;
      }
    }
    .item-content {
      color: #FFFFFF;
      .content-title {
        color: #FFFFFF;
      }
    }

  }
}
</style>
