<template>
  <router-link
    class="news-recommend-item-small"
    :to="{ name: 'NewsDetail', query: { id: data.id } }"
    :title="data.title"
  >
    <div class="item-pic">
      <img v-lazy="data.img"/>
      <span class="item-rank" :class="'item-rank--' + rank" :style="{ backgroundPosition: `0 ${-168 - 20 * rank }px`}"/>
    </div>
    <div class="item-content">
      <p class="in-two-line content-title">
        {{ data.title }}
      </p>
      <div class="content-date">{{ data.date }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'NewsItemSmall',
  props: {
    data: {
      type: Object,
      required: true
    },
    rank: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.news-recommend-item-small {
  width: 300px;
  height: 70px;
  color: #262626;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: #FFA73D;
    .item-pic {
      img {
        transform: scale(1.12);
      }
    }
  }
  .item-pic {
    position: relative;
    width: 105px;
    height: 70px;
    background-color: #edf1f4;
    margin-right: 10px;
    border-radius: 2px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      transition: all 1s;
    }
    .item-rank {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
      z-index: 1;
      border-radius: .5px .5px 2px .5px;
      background-image: url("../assets/img/news.png");
      background-repeat: no-repeat;
      background-size: 246px 1012px;
      background-color: #262626;
      opacity: 0.5;
      &--3 {
        background-color: #4285f4;
        opacity: 1;
      }
    }
  }
  .item-content {
    width: calc(100% - 80px);
    height: 70px;
    .content-title {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0;
      text-align: justify;
      line-height: 20px;
      max-height: 40px;
      overflow: hidden;
    }
    .content-date {
      width: 100%;
      margin-top: 8px;
      color: #999;
      font-size: 12px;
      line-height: 32px;
    }
  }
}
</style>
