<template>
  <div class="page-content news-page">
    <div class="banner-area">
      <div class="banner-slogan">
        <div class="title">零方资讯</div>
        <div class="desc">及时获取覆盖全场景的数智化新零售方案的前沿信息。</div>
      </div>
    </div>
    <div class="news-area">
      <div class="news-tab">
        <div
          class="tab-item"
          :class="{ 'tab-item--active': item.id === currentType }"
          v-for="item in newsTypeData"
          :key="item.id"
          @click="switchTab(item.id)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="news-list"
           v-if="currentData.length"
           v-loading="isLoading"
      >
        <NewsItem
          class="list-item"
          v-for="item in currentData"
          :key="item.id"
          :data-type="currentType"
          :data="item"
        />
        <div class="list-pagination" v-if="pagination.total > 10">
          <el-pagination
            layout="prev, pager, next"
            :total="pagination.total"
            @current-change="paginationChange"
          />
        </div>
      </div>
      <div class="news-list--placeholder" v-else>
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import NewsItem from '../components/NewsItem'
import { description, keywords } from '../data/meta'

export default {
  name: 'News',
  metaInfo: {
    title: '零方科技 | 零方资讯',
    meta: [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords + ',零方资讯，零方洞察，产品动态' }
    ]
  },
  components: {
    NewsItem
  },
  data() {
    return {
      isLoading: false,
      currentType: 0,
      currentData: [],
      pagination: {
        current: 1,
        total: 0
      }
    }
  },
  inject: ['news', 'ready'],
  computed: {
    newsTypeData() {
      return this.news()
    },
    allReady() {
      return this.ready()
    }
  },
  watch: {
    $route() {
      this.currentType = this.$route.query?.type * 1 || this.newsTypeData[0].id
      this.getInfoList(this.currentType)
    },
    allReady: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.state) {
          this.currentType = this.currentType ? this.currentType : this.newsTypeData[0].id
          this.getInfoList(this.currentType)
        }
      }
    }
  },
  methods: {
    getInfoList(id) {
      const self = this
      self.isLoading = true
      axios.get('/api/Open/GetNewsList', {
        params: {
          newsTypeId: id,
          pageIndex: self.pagination.current,
          pageSize: 10
        }
      })
        .then(response => {
          self.isLoading = false
          response = response.data
          if (response.Code === '0') {
            self.currentData = self.dealInfoListData(response.Data.List) || []
            self.pagination.total = response.Data.TotalCnt
          }
        })
        .catch(error => {
          self.isLoading = false
          console.log(error)
        })
    },
    dealInfoListData(data) {
      const result = []
      data.forEach(item => {
        result.push({
          id: item.Id,
          title: item.Title,
          desc: item.Desc,
          date: this.getFormatDate(item.CreateTime),
          img: item.Cover
        })
      })
      return result
    },
    getFormatDate(dateString) {
      if (!dateString) {
        return ''
      }
      return dateString.split(' ')[0]
    },
    paginationChange(current) {
      this.pagination.current = current
      this.getInfoList(this.currentType)
    },
    switchTab(type) {
      this.$router.push({ name: 'News', query: { type } })
    }
  },
  created() {
    this.currentType = this.$route.query?.type * 1 || this.newsTypeData[0].id
    this.getInfoList(this.currentType)
  }
}
</script>

<style lang="less" scoped>
.news-page {
  width: 100%;
  .banner-area {
    width: 100%;
    height: 500px;
    background: url("../assets/img/xw_banner.jpg") no-repeat;
    //background-size: 100% 100%;
    .banner-slogan {
      width: 1100px;
      margin: 0 auto;
      padding-top: 220px;
      color: #FFFFFF;
      .title {
        font-size: 46px;
        font-weight: 600;
        margin-bottom: 40px;
      }
      .desc {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
  .news-area {
    width: 1200px;
    margin: 40px auto;
    .news-tab {
      width: 100%;
      height: 40px;
      padding: 0 50px;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      .tab-item {
        flex: 1;
        height: 100%;
        padding: 0 15px;
        line-height: 40px;
        text-align: center;
        color: #333333;
        font-size: 22px;
        border-bottom: 2px solid transparent;
        transition: all .2s;
        cursor: pointer;
        &--active {
          color: #F17D1A;
          border-color: #F17D1A;
        }
      }
    }
    .news-list {
      min-height: 120px;
      &--placeholder {
        width: 100%;
        padding: 50px 0;
        text-align: center;
        color: #999;
      }
      .list-item {
      }
      .list-pagination {
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
